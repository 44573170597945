export function drawCVSSGauge(d3, gaugeElement) {
  // Sizing and color information
  const scale = d3.scaleLinear([10, 0], [15, 85]);
  const colorScale = d3.scaleSequential([10, 0], d3.interpolateRdYlGn);

  // Use the original element as a data source
  const gauge = d3.select(gaugeElement);
  const value = gauge.attr("value");

  // Draw a pretty gauge
  const svg = d3
    .create("svg")
    .classed("gauge", true)
    .attr("viewBox", "0 0 30 100")
    .attr("preserveAspectRatio", "none");
  svg
    .append("rect")
    .classed("track", true)
    .attr("x", 10)
    .attr("y", scale(10))
    .attr("width", 20)
    .attr("height", d3.max(scale.range()) - d3.min(scale.range()));
  svg
    .append("rect")
    .classed("bar", true)
    .attr("x", 10)
    .attr("y", scale(value))
    .attr("width", 20)
    .attr("height", d3.max(scale.range()) - scale(value))
    .attr("fill", colorScale(value))
    .append("title")
    .text(value + " out of 10.0");

  svg
    .append("text")
    .classed("legend", true)
    .attr("text-anchor", "middle")
    .attr("font-size", 10)
    .attr("x", 20)
    .attr("y", 10)
    .text("10.0");
  svg
    .append("text")
    .classed("legend", true)
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "hanging")
    .attr("font-size", 10)
    .attr("x", 20)
    .attr("y", 90)
    .text("0.0");

  svg
    .append("polygon")
    .classed("marker", true)
    .attr("points", "0,0 -7,-7 -7,7")
    .attr("transform", "translate(10 " + scale(value) + ")");

  gauge.node().replaceWith(svg.node());
}
