import { drawReportTimeline } from "./timeline.js";
import { drawCVSSGauge } from "./cvssgauge.js";

htmx.on("htmx:load", function (evt) {
  const dataTable = evt.detail.elt.querySelector("#report-timeline-data");
  const scoreMeter = evt.detail.elt.querySelector("#cvss-score-meter");
  if (dataTable !== null || scoreMeter !== null) {
    import(/* webpackPrefetch: true */ "d3").then((d3) => {
      drawReportTimeline(d3, "#report-timeline", dataTable);
      drawCVSSGauge(d3, scoreMeter);
    });
  }

  // Patch up help tooltips to auto-close.
  const helps = document.querySelectorAll("details.help");
  for (const help of helps) {
    help.addEventListener("focusout", function (event) {
      this.removeAttribute("open");
    });
  }
});

// Load the swagger stuff on-demand, instead of packing it into main.
window.loadSwaggerUI = async function (url) {
  const { default: SwaggerUI } = await import("swagger-ui");
  SwaggerUI({
    url: url,
    dom_id: "#swagger-ui",
    layout: "BaseLayout",
    deepLinking: true,
    showExtensions: true,
    showCommonExtensions: true,

    presets: [SwaggerUI.presets.apis, SwaggerUI.SwaggerUIStandalonePreset],
  });
};
