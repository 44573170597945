function yPos(offset, i) {
  return offset * (1 - (i % 2) * 2);
}

function drawMarker(parent, offset, i) {
  parent.append("line").attr("x1", 0).attr("y1", 4).attr("x2", 5).attr("y2", 4);
  parent
    .append("line")
    .attr("x1", 0)
    .attr("y1", 4)
    .attr("x2", 0)
    .attr("y2", 4 + yPos(offset, i));
  return parent
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 4 + yPos(offset + 7, i))
    .attr("r", 7);
}

export function drawReportTimeline(d3, containerSelector, dataTable) {
  const container = d3.select(containerSelector);
  container.selectChildren().remove();

  // We bin data into two arrays, one for events in the past, one for events in
  // the future, if any. Future events don't have dates, so we display them in
  // order with even spacing.
  const pastData = [];
  const futureData = [];
  const rows = dataTable
    .getElementsByTagName("tbody")[0]
    .getElementsByTagName("tr");
  for (let i = 0; i < rows.length; i++) {
    const cells = rows[i].getElementsByTagName("td");
    const time = cells[1].getElementsByTagName("time")[0];
    if (time == null) {
      futureData.push({
        name: cells[0].innerText,
      });
    } else {
      pastData.push({
        name: cells[0].innerText,
        date: new Date(time.getAttribute("datetime")),
      });
    }
  }

  // Widths will have a % added to them at the appropriate junctures.
  const width = 100;
  const startX = 5;
  const endX = 85;
  const height = 150;
  const labelOffsetY = 36;

  const presentX =
    (endX - startX) *
      (pastData.length / (pastData.length + futureData.length)) +
    startX;

  const formatTime = d3.utcFormat("%Y-%m-%d");
  const xScalePast = d3
    .scaleTime()
    .domain(d3.extent(pastData, (d) => d.date))
    .range([startX, presentX]);
  const xScaleFuture = d3
    .scaleLinear()
    .domain([-1, futureData.length - 1])
    .range([presentX, endX]);

  const xOffset = d3.local();
  function xScalePastMinSep(d, i, nodes) {
    let x = xScalePast(d.date);

    if (i > 0) {
      const prevX = xOffset.get(nodes[i - 1]);
      x = Math.max(x, prevX + 1);
    }

    xOffset.set(this, x);
    return x + "%";
  }

  const svg = container.append("svg");

  // Prepare gradient
  var defs = svg.append("defs");
  var gradient = defs
    .append("linearGradient")
    .attr("id", "legendBgGradient")
    .attr("x1", "0%")
    .attr("x2", "100%")
    .attr("y1", "50%")
    .attr("y2", "50%");
  gradient.append("stop").attr("class", "start").attr("offset", "0%");
  gradient.append("stop").attr("class", "end").attr("offset", "5%");
  gradient.append("stop").attr("class", "start").attr("offset", "100%");

  svg
    .append("line")
    .attr("x1", startX - 1 + "%")
    .attr("y1", height / 2)
    .attr("x2", endX + 1 + "%")
    .attr("y2", height / 2)
    .attr("class", "timeline-base");
  svg
    .selectAll(".timeline-filled")
    .data(pastData)
    .enter()
    .append("line")
    .attr("y1", height / 2)
    .attr("y2", height / 2)
    .attr("x2", xScalePastMinSep)
    .attr("x1", (d, i, nodes) => {
      if (i === 0) {
        return startX - 1 + "%";
      }

      return xOffset.get(nodes[i - 1]) + "%";
    })
    .attr("class", "timeline-filled");

  svg
    .selectAll(".event-legend.past")
    .data(pastData)
    .enter()
    .append("svg")
    .attr("class", "event-legend past")
    .attr("x", xScalePastMinSep)
    .attr("y", (d, i) => height / 2 - 4 - yPos(labelOffsetY, i))
    .each(function (d, i) {
      const layer = d3.select(this);
      if (i + 1 === pastData.length) {
        layer.classed("present", true);
      }

      layer
        .append("rect")
        .attr("class", "event-background")
        .attr("x", -15)
        .attr("y", -16)
        .attr("height", 38)
        .attr("width", 300);

      drawMarker(layer, labelOffsetY - 7, i)
        .on("mouseover", function () {
          d3.select(this).classed("fakehover", true);
          layer.raise();
        })
        .on("mouseleave", function () {
          d3.select(this).classed("fakehover", false);
        });

      // Event name
      layer
        .append("text")
        .attr("class", "event-name")
        .attr("x", 10)
        .attr("y", 0)
        .text(d.name)
        .attr("text-anchor", "start");

      // Event date
      layer
        .append("text")
        .attr("class", "event-date")
        .attr("x", 10)
        .attr("y", 16)
        .text(formatTime(d.date))
        .attr("text-anchor", "start");
    });

  svg
    .selectAll(".event-legend.future")
    .data(futureData)
    .enter()
    .append("svg")
    .attr("class", "event-legend future")
    .attr("x", (d, i) => xScaleFuture(i) + "%")
    .attr(
      "y",
      (d, i) => height / 2 - 4 - yPos(labelOffsetY, i + pastData.length),
    )
    .each(function (d, i) {
      const layer = d3.select(this);
      layer
        .append("rect")
        .attr("class", "event-background")
        .attr("x", -15)
        .attr("y", -16)
        .attr("height", 38)
        .attr("width", 300);
      drawMarker(layer, labelOffsetY - 7, i + pastData.length)
        .on("mouseover", function () {
          layer.raise();
        })
        .on("mouseleave", function () {
          d3.select(this).classed("fakehover", false);
        });

      // Event name
      layer
        .append("text")
        .attr("class", "event-name")
        .attr("x", 10)
        .attr("y", 8)
        .text(d.name)
        .attr("text-anchor", "start");
    });
}
